.theme-antd {

    .ant-dropdown-menu-item.current, 
    .ant-dropdown-menu-submenu-title.current {
        color: #1890ff;
        background-color: #e6f7ff;
    }

    .ant-divider-vertical {
        border-left-color: #ccc;
    }

    .ant-modal-content .add-footer {
        border-top: 1px solid #f0f0f0;
    }

    .ant-btn-background-ghost {
        color: rgba(0, 0, 0, 0.65);
        border-color: rgba(0, 0, 0, .25);

        &:hover {
            color: #1890ff;
            border-color: #1890ff;
        }
    }
}