$dark: #0a0a0d;
$background: #1a191e;
$panel: #2b2a2e;

$border: #38383a;
$light-border: #28272c;

$text: #dcdcdd;
$light-text: #f8f8f7;
$shaded-text: #555558;
$medi-text: #9c9c9f;

$primary: #1b7689;
$light-primary: #1b8ba3;
$danger: #ae333a;
$light-danger: #df3b40;

$important-urgent: #ae333a;
$important: #1c7b53;
$urgent: #c77047;

.theme-verve {

    /* GLOBAL */

    a,
    span.link {
        color: $light-primary;

        &:hover {
            color: $light-primary;
            text-decoration-color: $light-primary;
        }
    }

    .app-head {
        background-color: $panel;
        color: $medi-text;

        .tags-selection {
            .ant-btn {
                border-color: $border;
                background-color: $panel;

                &.ant-btn-primary {
                    background-color: $panel;
                    border-color: $primary;
                    color: $light-primary;
                }
            }
        }
    }

    .text-size {
        color: $medi-text;
    }
    
    .color-mode {
        &.mode-adaptive {
            &:after {
                background: radial-gradient(
                    circle, 
                    rgba(43,43,46,1) 0%,
                    rgba(43,43,46,1) 20%,
                    rgba(43,43,46,0) 80%,
                    rgba(43,43,46,0) 100%
                );
            }
        }
    }

    .font-size-slider {
        .ant-slider-dot:before {
            color: $medi-text;
        }

        .ant-slider-handle:before {
            color: $text;
            text-shadow: -0.5px 0px 0px $text, 0.5px 0px 0px $text;
        }
    }

    /* RADIO */

    .ant-radio-group {
        color: $text;
    }

    .ant-radio-button-wrapper {
        color: $medi-text;
        background: $panel;
        border: 1px solid $light-border;
    }

    .ant-radio-button-wrapper:not(:first-child)::before {
        background-color: $background;
    }

    .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
        border-right-color: $primary;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        color: $text;
        border-color: $primary;
        box-shadow: none;

        &:before {
            background-color: $primary;
        }
    }

    /* CHECKBOX */
    
    .ant-checkbox-wrapper {
        color: $text;
    }

    .ant-checkbox-inner {
        background-color: $dark;
        border-color: $border;
    }

    .ant-checkbox-wrapper:hover,
    .ant-checkbox:hover {
        .ant-checkbox-inner {
            border-color: $border;
        }
    }

    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: $border;
    }

    .ant-checkbox-checked::after {
        border-color: $border;
    }

    /* SELECT */

    .ant-select {
    
        .ant-select-arrow {
            color: $shaded-text;
        }

        .ant-select-selection-search {
            color: $text;
        }
    
        .ant-select-selection-placeholder {
            color: $shaded-text;
            opacity: 1;
            font-weight: 600;
        }

        &:not(.ant-select-disabled):not(.ant-select-focused):hover {
            .ant-select-selector {
                border-color: transparent;
            }
        }

        &.ant-select-focused {
            &.ant-select-multiple,
            &.ant-select-single {
                .ant-select-selector {
                    border-color: $border;
                    box-shadow: none;
                }
            }
        }

        &.ant-select-multiple {

            .ant-select-selector {
                background-color: $dark;
                border-color: transparent;
            }
    
            .ant-select-selection-item {
                background-color: $panel;
                color: $text;
                border: none;
            }

            .ant-select-selection-item-remove {
                color: $text;
            }
        }

        &.ant-select-single {
            &:not(.ant-select-customize-input) {
                .ant-select-selector {
                    background-color: $dark;
                    border-color: transparent;
                    color: $text;
                }
            }
        }

    }
    
    .ant-select-dropdown {
        background-color: $panel;
        border: 1px solid $light-border;

        .ant-select-item {
            color: $text;
        }

        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
            background-color: $light-border;
    
            .ant-select-item-option-state {
                color: $text;
            }
        }
    
        .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
            background-color: $light-border;
            color: $text;
        }
    }

    .ant-input {
        background-color: $dark;
        border-color: transparent;
        color: $text;

        &:focus {
            border-color: $border;
            box-shadow: none;
        }

        &::placeholder {
            color: $shaded-text;
            font-weight: 600;
        }
    }

    .ant-btn {
        background-color: transparent;
        border-color: transparent;
        color: $text;

        &:hover {
            color: $light-text;
        }

        &.ant-btn-dangerous {
            color: $light-danger;
        }
    }

    .ant-btn-background-ghost {
        border-color: transparent;
    }

    .ant-btn-primary {
        background-color: $primary;
        color: $text;
        border-color: transparent;

        &:hover {
            background-color: $light-primary;
            color: $light-text;
        }
    }
    
    .ant-btn-primary[disabled], 
    .ant-btn-primary[disabled]:hover, 
    .ant-btn-primary[disabled]:focus, 
    .ant-btn-primary[disabled]:active {
        background-color: transparent;
        color: $shaded-text;
        border-color: $border;
    }

    .ant-btn-dangerous.ant-btn-primary {
        background-color: $danger;
        color: $text;

        &:hover, 
        &:focus {
            background-color: $light-danger;
            color: $light-text;
            border-color: transparent;
        }
    }

    .ant-btn-secondary {
        color: $medi-text;

        &:hover {
            color: $text;
        }
    }

    [ant-click-animating-without-extra-node='true']::after {
        display: none;
    }

    /* MODAL */

    .ant-modal-content {
        background-color: $background;
        border: 1px solid $light-border;
    }

    .ant-modal-header {
        background-color: $panel;
        border: none;
    }

    .ant-modal-close-x {
        color: $shaded-text;
    }

    .ant-modal-title {
        color: $text;
        font-weight: 100;
    }

    .ant-modal-body {
        color: $medi-text;

        hr {
            background-color: $panel;
        }
    }

    .ant-modal-footer,
    .add-footer {
        border: none;
        background-color: $panel;
    }

    .modal-off-footer {
        color: $shaded-text;
    }

    /* POPOVER */
    
    .ant-popover-inner {
        background-color: $panel;
    }

    .ant-popover-inner-content {
        color: $medi-text;
        padding: 8px 12px;
    }

    .ant-popover-placement-top, 
    .ant-popover-placement-topLeft, 
    .ant-popover-placement-topRight {
        & > .ant-popover-content > .ant-popover-arrow {
            border-right-color: $panel;
            border-bottom-color: $panel;
        }
    }

    /* MATRIX */

    .ematrix {
        background-color: $panel;

        .ematrix-section {
            background-color: $background;

            .h-label,
            .v-label {
                color: $shaded-text;
            }
        }
    }

    /* EVENTS */

    .ematrix-event{
        &.no-color .ematrix-event-content {
            border: 1px solid $border;
            background-color: $panel;
            color: $light-text;
        }

        &.color-full {
            &.important {
                .ematrix-event-content {
                    border-color: transparent;
                    background-color: $important;
                    color: $light-text;
                }
                
                &.urgent  {
                    .ematrix-event-content {
                        border-color: transparent;
                        background-color: $important-urgent;
                        color: $light-text;
                    }
                }
            }

            &.urgent {
                .ematrix-event-content {
                    border-color: transparent;
                    background-color: $urgent;
                    color: $light-text;
                }
            }
        }

        &.color-tiny {
            &.important {
                .ematrix-event-content {
                    border-color: $important;
                    background-color: $panel;
                    color: $light-text;
                }
                
                &.urgent  {
                    .ematrix-event-content {
                        border-color: $important-urgent;
                        background-color: $panel;
                        color: $light-text;
                    }
                }
            }

            &.urgent {
                .ematrix-event-content {
                    border-color: $urgent;
                    background-color: $panel;
                    color: $light-text;
                }
            }
        }
    }

    &.in-unimportant.in-nonurgent {
        .ematrix-event.react-draggable-dragging {
            &.color-full,
            &.color-adaptive {
                .ematrix-event-content {
                    background-color: $panel;
                    color: $medi-text;
                }
            }
            &.color-tiny {
                .ematrix-event-content {
                    border: 1px solid $border;
                    background-color: $panel;
                    color: $medi-text;
                }
            }
        }
    }

    
    &.in-important {
        .ematrix-event.react-draggable-dragging {
            &.color-full,
            &.color-adaptive {
                .ematrix-event-content {
                    background-color: $important;
                    color: $light-text;
                }
            }
            &.color-tiny {
                .ematrix-event-content {
                    border-color: $important;
                    background-color: $panel;
                    color: $light-text;
                }
            }
        }

        &.in-urgent {
            .ematrix-event.react-draggable-dragging {
                &.color-full,
                &.color-adaptive {
                    .ematrix-event-content {
                        background-color: $important-urgent;
                        color: $light-text;
                    }
                }
                &.color-tiny {
                    .ematrix-event-content {
                        border-color: $important-urgent;
                        background-color: $panel;
                        color: $light-text;
                    }
                }
            }
        }
    }

    &.in-urgent {
        .ematrix-event.react-draggable-dragging {
            &.color-full,
            &.color-adaptive {
                .ematrix-event-content {
                    background-color: $urgent;
                    color: $light-text;
                }
            }
            &.color-tiny {
                .ematrix-event-content {
                    border-color: $urgent;
                    background-color: $panel;
                    color: $light-text;
                }
            }
        }
    }

    .ematrix-event-content {
        border: 1px solid $border;
        background-color: $panel;
        color: $medi-text;
    }

    .event-tags {
        border-top-color: $background;

        & > span {
            border-color: $border;
            background-color: $border;
            color: $medi-text;
        }
    }

    /* TRASH BIN */

    .trash-bin {
        color: $danger;
        background-color: $panel;
        border: 1px solid $border;

        &.drag-over {
            background-color: $danger;
            color: $text;
        }
    }

    /* DROPDOWN */

    .ant-dropdown-menu {
        background-color: $panel;
        border: 1px solid $background;
    }

    .ant-dropdown-menu-item, 
    .ant-dropdown-menu-submenu-title {
        color: $medi-text;
        line-height: 2;
        padding-top: 4px;
        margin: 4px 0;
        border-left: 2px solid transparent;
    }

    .ant-dropdown-menu-item.current, 
    .ant-dropdown-menu-submenu-title.current,
    .ant-dropdown-menu-item-selected, 
    .ant-dropdown-menu-submenu-title-selected, 
    .ant-dropdown-menu-item-selected > a, 
    .ant-dropdown-menu-submenu-title-selected > a {
        color: $light-primary;
        background-color: transparent;
        border-left-color: $primary;
    }

    .ant-dropdown-menu-item, 
    .ant-dropdown-menu-submenu-title {
        &:hover {
            color: $text;
            background-color: transparentize($color: $shaded-text, $amount: .8);
        }
    }

    .ant-tabs {
        color: $medi-text;
        margin-top: -20px;
    }

    .ant-tabs-top > .ant-tabs-nav::before, 
    .ant-tabs-bottom > .ant-tabs-nav::before, 
    .ant-tabs-top > div > .ant-tabs-nav::before, 
    .ant-tabs-bottom > div > .ant-tabs-nav::before {
        border-bottom-color: $light-border;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
    .ant-tabs-tab:hover,
    .ant-tabs-tab:focus,
    .ant-tabs-tab:active,
    .ant-tabs-tab-btn:focus, 
    .ant-tabs-tab-remove:focus, 
    .ant-tabs-tab-btn:active, 
    .ant-tabs-tab-remove:active {
        color: $primary;
    }

    .ant-tabs-ink-bar {
        background: $primary;
    }
}

.ant-divider-vertical {
    border-left-color: $shaded-text;
}