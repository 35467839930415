.app-page {
  height: 100%;
}

a,
span.link {
  color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color .2s ease;
  font-weight: 600;

  &:active,
  &:hover {
    text-decoration: underline;
    text-decoration-color: #1890ff;
  }
}

label {
  padding: 4px 0;
  display: block;
  font-size: 12px;
}

.ant-select-selection-placeholder {
  opacity: 1;
  color: #999;
}