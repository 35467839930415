.app-pagination {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .ant-pagination-item {
        display: none;
    }

    .label {
        font-size: 12px;
        line-height: 14px;
        color: #7D7D7D;
        margin-bottom: 2px;
    }

    .ant-select {
        margin-left: 8px;

        .ant-select-selector {
            font-size: 13px;
            line-height: 15px;
            color: #1F2022;
            border: none;
            padding: 1px 18px;
        }

        &.ant-select-focused {
            .ant-select-selector {
                box-shadow: none;
            }
        }
        
        .ant-select-arrow {
            svg {
                fill: #000;
            }
        }
    }

    .current-total {
        font-size: 13px;
        line-height: 15px;
        color: #7D7D7D;
        margin: 0 40px;
    }

    .ant-pagination-item-link {
        border: none;
    }

    .ant-pagination-jump-next,
    .ant-pagination-jump-prev {
        display: none;
    }
}