.settings-modal {
    top: 50px;

    label {
        margin-top: 16px;

        &:first-child {
            margin-top: 0;
        }

        &.ant-radio-button-wrapper {
            margin-top: 0;
        }
    }

    .ant-row {
        margin-bottom: 8px;
    }
    .ant-modal-footer {
        padding: 0;
    }

    .inline-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .anticon {
            margin-right: 8px;
        }
    }
}