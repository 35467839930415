.app-head {
    background-color: #fff;
    padding: 0 8px;
    height: 48px;
    line-height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;

    & > .ant-btn {
        flex: 0 0 32px;
    }

    .ant-btn.ant-btn-background-ghost {
        border-color: transparent;
    }
    
    .ant-select-selection-placeholder,
    .ant-select-multiple .ant-select-selection-item {
        font-size: 12px;
    }

    .color-mode {
        vertical-align: middle;
        filter: saturate(1);
        transition: filter .2s ease;
        position: relative;
        transform: scale(.75);

        svg * {
            stroke-width: 0;
            transition: fill .2s ease, stroke .2s ease, stroke-width .2s ease;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        &.mode-none {
            filter: saturate(0);
        }

        &.mode-tiny {
            svg * {
                fill: transparent;
                stroke-width: 20px;
            }
        }

        &.mode-adaptive {
            &:after {
                background: radial-gradient(
                    circle, 
                    rgba(238,238,238,1) 0%,
                    rgba(238,238,238,1) 20%,
                    rgba(238,238,238,0) 80%,
                    rgba(238,238,238,0) 100%
                );
            }
        }
    }

    .text-size {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        margin-right: 8px;
        cursor: pointer;
    }

    .tags-selection {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-right: auto;
        overflow: hidden;
        flex: 1 1 auto;
        flex-wrap: wrap;
        height: 100%;
        line-height: 48px;
        padding-right: 32px;
        position: relative;

        label {
            margin-right: 8px;
            height: 48px;
            line-height: 48px;
            padding: 0;
        }

        .tags-clear {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 8px;
            transition: opacity .1s ease;
            opacity: .5;
            line-height: 20px;
            text-align: center;
            cursor: pointer;

            &:hover {
                opacity: 1;
            }

            &.invis {
                opacity: 0;
                pointer-events: none;
            }
        }

        .ant-btn {
            font-size: 12px;
            margin-right: 8px;
            border-radius: 3px;
            position: relative;
            z-index: 2;

            span::first-letter {
                text-transform: capitalize;
            }
        }

        .overflow-button {
            pointer-events: none;
            opacity: 0;
            padding-left: 8px;
            padding-right: 8px;
            position: absolute;
            left: calc(100% - 32px);
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            transition: none;
    
            &.visible {
                opacity: 1;
                pointer-events: all;
            }
        }
    }

    .theme-selector {
        transform: scale(.8);
        margin-right: 8px;
    }

    .language {
        transform: scale(.9);
        margin-right: 4px;
    }

    .ant-divider-vertical {
        height: 1.6em;
        top: 0;
        margin-right: 10px;
    }
}

.tag-item::first-letter {
    text-transform: capitalize;
}

.ant-dropdown-menu {
    padding: 0;
}