
.ematrix-event {
    position: absolute;
    cursor: grab;
    width: 1px;
    height: 1px;
    z-index: 10;
    pointer-events: all;
    user-select: none;

    &.react-draggable-dragging {
        cursor: grabbing;
    }

    &.no-color {
        .ematrix-event-content {
            background-color: #fff;
            color: #111;
        }
    }

    &.color-full {
        &.important {
            .ematrix-event-content {
                background-color: #83c662;
                color: #111;
            }

            &.urgent {
                .ematrix-event-content {
                    background-color: #c52b1b;
                    color: #fff;
                }
            }
        }

        &.urgent {
            .ematrix-event-content {
                background-color: #f1aa4a;
                color: #111;
            }
        }
    }

    &.color-tiny {

        &.important {
            .ematrix-event-content {
                border-color: #83c662;
                background-color: #fff;
                color: #111;
                border-left-width: 5px;
                padding-left: 7px;
            }

            &.urgent {
                .ematrix-event-content {
                    border-color: #c52b1b;
                    background-color: #fff;
                    color: #111;
                    border-left-width: 5px;
                    padding-left: 7px;
                }
            }
        }

        &.urgent {
            .ematrix-event-content {
                border-color: #f1aa4a;
                background-color: #fff;
                color: #111;
                border-left-width: 5px;
                padding-left: 7px;
            }
        }
    }
}

.in-unimportant.in-nonurgent {
    .ematrix-event.react-draggable-dragging {
        &.color-full,
        &.color-adaptive {
            .ematrix-event-content {
                background-color: #eee;
                color: #666;
            }
        }
    }
}

.in-important {
    .ematrix-event.react-draggable-dragging {
        &.color-full,
        &.color-adaptive {
            .ematrix-event-content {
                background-color: #83c662;
                color: #111;
            }
        }
        &.color-tiny .ematrix-event-content {
            border-color: #83c662;
            background-color: #fff;
            color: #111;
            border-left-width: 5px;
            padding-left: 7px;
        }
    }

    &.in-urgent {
        .ematrix-event.react-draggable-dragging {
            &.color-full,
            &.color-adaptive {
                .ematrix-event-content {
                    background-color: #c52b1b;
                    color: #fff;
                }
            }
            &.color-tiny .ematrix-event-content {
                border-color: #c52b1b;
                background-color: #fff;
                color: #111;
                border-left-width: 5px;
                padding-left: 7px;
            }
        }
    }
}

.in-urgent {
    .ematrix-event.react-draggable-dragging {
        &.color-full,
        &.color-adaptive {
            .ematrix-event-content {
                background-color: #f1aa4a;
                color: #111;
            }
        }
        &.color-tiny .ematrix-event-content {
            border-color: #f1aa4a;
            background-color: #fff;
            color: #111;
            border-left-width: 5px;
            padding-left: 7px;
        }
    }
}

.ematrix-event-content {
    transform: translate(-50%,-50%) translateZ(0);
    padding: 8px 12px;
    font-size: 1em;
    line-height: 1.2em;
    border: 1px solid #ddd;
    border-radius: 3px;
    border-bottom-color: #aaa;
    position: absolute;
    left: 0;
    top: 0;
    min-width: 13em;
    text-align: center;
    background-color: #eee;
    color: #666;
    will-change: transform;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
    transition: all .2s ease;
    font-weight: 500;
}

.event-tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 0 0;
    margin-top: 8px;
    border-top: 1px solid #eee;

    & > span {
        flex: 0 0 auto;
        display: inline-block;
        padding: 2px 4px;
        border: 1px solid #ccc;
        background-color: #f9f9f9;
        color: #666;
        font-size: .6em;
        border-radius: 2px;
        margin-right: 4px;
        cursor: pointer;
        transition: background-color .2s ease, border-color .2s ease;

        &:hover {
            background-color: #eeffff;
            border-color: #cce;
        }
    }
}

.ant-popover-inner-content {
    min-width: 150px;
    
    i {
        color: #999;
        font-style: italic;
        font-size: .75em;
    }

    p {
        margin: 0;
        font-size: 12px;
    }
}