.ematrix-section {
    flex: 0 0 calc(50vw - 1px);
    height: calc(50vh - 26px);
    margin: .5px;
    background-color: #e5e5e5;
    box-sizing: border-box;
    position: relative;
    user-select: none;

    label {
        pointer-events: none;
        white-space: nowrap;
        position: absolute;
        display: block;
        font-size: 12px;
        color: #999;
        transform-origin: 0 0;
        left: 0;
        bottom: 0;
        padding-left: 24px;

        &.h-label {
            transform: rotate(-90deg);
            padding-left: 0;
        }
    }

    &.urgent {

        .h-label {
            left: auto;
            right: 0;
            bottom: 0;
            transform-origin: 100% 0;
            transform: rotate(90deg);
        }

        .v-label {
            left: auto;
            right: 0;
            padding-left: 0;
            padding-right: 24px;
        }
    }

    &.important {
        .v-label {
            bottom: auto;
            top: 0;
        }
        .h-label {
            bottom: auto;
            top: 0;
            padding-left: 0;
            padding-right: 24px;
            transform: rotate(-90deg) translateX(-100%);
        }

        &.urgent {
            .h-label {
                padding-left: 24px;
                padding-right: 0;
                transform: rotate(90deg) translateX(100%);
            }
        }
    }
}