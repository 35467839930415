.login-form-container {
    width: 320px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    text-align: center;

    .form-field + .form-field {
        margin-top: 16px;
    }

    h4 {
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        color: #1F2022;
        margin-left: -10px;
        margin-right: -10px;
    }

    p {
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        color: #7D7D7D;
        margin-bottom: 32px;
    }

    .login-header {
        text-align: center;
        margin-bottom: 32px;
    }

    .link {
        display: block;
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        margin-top: 14px;
    }

    .react-ripples {
        width: 100%;
        margin-top: 32px;

        & + .link {
            margin-top: 32px;
        }
    }

    .ant-btn {
        width: 100%;
    }

    .ant-alert {
        margin-top: 32px;
        display: inline-block;
    }
}