.ant-modal-body {
    .ant-input + .ant-input,
    .ant-input + .ant-select {
        margin-top: 16px;
    }
}

.ant-input::placeholder {
    color: #999;
}

.ant-modal {
    .modal-subfoot {
        position: absolute;
        left: 15px;
        bottom: -40px;
    }
}