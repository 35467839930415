.theme-changing * {
    transition: none !important;
}

.ant-layout-content {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.ematrix {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
    background-color: #d5d5d5;
    overflow: hidden;
    flex: 1 1 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
}

.trash-bin {
    position: absolute;
    left: 0;
    top: 100%;
    font-size: 32px;
    color: #c52b1b;
    width: 128px;
    height: 128px;
    border-radius: 64px;
    box-shadow: 0px 2px 10px rgba(0,0,0,.3);
    background-color: #fff;
    transform: translate(-100%,0);
    transition: transform .5s ease, background-color .2s ease, color .2s ease;

    .anticon {
        transform: translate(60%,-70%);
    }

    &.drag-over {
        background-color: #c52b1b;
        color: #fff;
    }
}

.react-draggable-transparent-selection {
    .trash-bin {
        transform: translate(-50%,-50%);
    }
}

.no-footer .ant-modal-footer {
    display: none;
}

.ant-modal-content .add-footer {
    margin: 24px -24px -24px;
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    padding: 10px 16px;

    .ant-btn {
        margin-left: 16px;

        &:first-child {
            margin-left: 0;
        }

        &:nth-child(2) {
            margin-left: auto;
        }
    }
}

.ant-modal-body {
    position: relative;

    hr {
        border: none;
        height: 1px;
        background-color: #eee;
        margin-top: 32px;
    }

    .modal-off-footer {
        position: absolute;
        left: 0;
        top: 100%;
        padding-left: 20px;
        padding-top: 8px;
        
        .ant-checkbox-wrapper {
            font-size: 12px;
        }

        small {
            display: block;
            padding-left: 24px;
            opacity: .75;
            transform: translateY(-4px);
        }
    }
}

.events-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.ant-slider-with-marks {
    margin: 0 16px;
    flex: 0 0 100px;

    .ant-slider-track {
        display: none;
    }

    .ant-slider-dot {
        background-color: transparent;
        border: none;
        box-shadow: none;
        line-height: 12px;

        &:before {
            content: 'A';
            opacity: .5;
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            height: 24px;
            line-height: 24px;
        }

        &:nth-child(1) {
            font-size: 7px;
        }

        &:nth-child(2) {
            font-size: 9px;
        }

        &:nth-child(3) {
            font-size: 11px;
        }

        &:nth-child(4) {
            font-size: 12px;
        }

        &:nth-child(5) {
            font-size: 14px;
        }

        &:nth-child(6) {
            font-size: 16px;
        }

        &:nth-child(7) {
            font-size: 18px;
        }
    }

    .ant-slider-handle {
        background-color: transparent;
        border: none;
        box-shadow: none;
        position: relative;

        &:before {
            content: 'A';
            font-size: 1em;
            height: 24px;
            line-height: 24px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            text-shadow: -.5px 0px 0px #333, .5px 0px 0px #333;
        }
    }

    &:hover .ant-slider-rail,
    .ant-slider-rail {
        background-color: transparent;
    }

    .ant-slider-mark-text {
        display: none;
    }
}

.ant-select-arrow {
    transition: transform .2s ease;
}

.ant-select-open {
    .ant-select-arrow {
        transform: rotate(-180deg);
    }
}

.ant-select-item-option-content,
.ant-select-selection-item-content,
.ant-select-selection-item,
.event-tags > span {
    text-transform: lowercase;

    &:first-letter {
        text-transform: uppercase;
    }
}

.ant-btn-secondary {
    color: #333;
}